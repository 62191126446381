import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { FormControl, Grid, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';

import DateRangePicker from 'src/components/DateRangePicker';
import TitleFont from 'src/components/Typography/TitleFont';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
  select: {
    fontFamily: theme.font.menu,
  },
}));

const DateFilterControl = (props) => {
  const { id, title, onChange } = props;
  const classes = useStyles();
  let labelId = id + '-label';

  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
    dateFilterOption: 0, //All: 0,Blank: 1, NotBlank: 2, Range: 3
  });

  const previousUpdated = useRef();
  const [updated, setUpdated] = useState();

  useEffect(() => {
    if (previousUpdated.current !== updated) {
      previousUpdated.current = updated;
      onChange && onChange(dateFilter);
    }
  }, [onChange, dateFilter, updated]);

  const handleOnDateRangeChanged = ({ fromDate, toDate }) => {
    console.log('handleOnDateRangeChanged');
    setDateFilter({
      ...dateFilter,
      startDate: fromDate,
      endDate: toDate,
    });
    if (fromDate && toDate) {
      setUpdated(new Date());
    }
  };

  const handleOnDateFilterOptionChanged = (event) => {
    const dateFilterOption = event.target.value;
    console.log('handleOnDateFilterOptionChanged', dateFilterOption);
    setDateFilter({
      dateFilterOption: dateFilterOption,
      startDate: null,
      endDate: null,
    });

    if (dateFilterOption !== 3) {
      setUpdated(new Date());
    }
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={clsx(classes.formControl, 'exclude')}>
          <InputLabel id={labelId}>
            <TitleFont>{title}</TitleFont>
          </InputLabel>
          <Select labelId={labelId} id={`select-${labelId}`} label={title} value={dateFilter.dateFilterOption} onChange={handleOnDateFilterOptionChanged}>
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={1}>Blank</MenuItem>
            <MenuItem value={2}>Not Blank</MenuItem>
            <MenuItem value={3}>Date Range</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        {dateFilter.dateFilterOption === 3 && <DateRangePicker title={'Date Range'} onChange={handleOnDateRangeChanged} />}
      </Grid>
    </Grid>
  );
};

export default DateFilterControl;

DateFilterControl.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
};
