import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';

import { Divider, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/styles';

import { UserContext } from 'src/contexts/UserContext';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import customerAccountApiService from 'src/services/customerAccount.api.service';
import formErrorFactory from 'src/services/formError.factory';
import Button from 'src/wrappers/Button';

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
}));

const FeatureSettingControl = (props) => {
  let { featureEnabled, featureName, onChange, label } = props;
  const [userContext] = useContext(UserContext);
  const isDisabled = () => {
    return !(userContext.isAdmin && userContext.permissions.canManageCustomerAccounts);
  };
  return (
    <FormControlLabel
      control={<Checkbox checked={featureEnabled} onChange={onChange} name={featureName} color="primary" disabled={isDisabled()} />}
      label={label}
    />
  );
};

const FeaturesSettingEntry = (props) => {
  const classes = useStyles();
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [userContext] = useContext(UserContext);
  let { id } = useParams();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showErrorSnackBar, showSuccessSnackBar } = useGlobalSnackBar();
  const { catchApiError } = useCatchAPIError();

  const [model, setModel] = useState({
    accountId: '',
    accountName: '',
    enabledTrackAndTrace: false,
    enabledEDIReport: false,
    enabledTrendsOverTime: false,
    enabledCommercialInvoices: false,
    enabledSpendPerSuppliers: false,
    enabledProducts: false,
    enabledPurchaseOrder: false,
    enabledPurchaseOrderLinesFeature: false,
    enabledReportFilesFeature: false,
    enabledPurchaseOrderLineSpendsFeature: false,
    enabledPurchaseOrderLineMilestonesFeature: false,
    enabledExceptionsFeature: false,
    enabledPublicTrackAndTraceFeature: false,
    enabledOverviewFeature: false,
    enabledPortPairDataFeature: false,
    enabledShipmentsFeature: false,
    enabledShipmentSpendsFeature: false,
    enabledConsolidatedShipmentsSpendsFeature: false,
    enabledSiteDeliveredContainersFeature: false
  });

  useEffect(() => {
    if (id) {
      startProgress();
      customerAccountApiService
        .getCustomerAccountFeaturesEntry(id)
        .then((result) => {
          let model = {
            accountId: result.data.accountId,
            accountName: result.data.accountName,
            enabledTrackAndTrace: result.data.enabledTrackAndTrace,
            enabledEDIReport: result.data.enabledEDIReport,
            enabledTrendsOverTime: result.data.enabledTrendsOverTime,
            enabledCommercialInvoices: result.data.enabledCommercialInvoices,
            enabledSpendPerSuppliers: result.data.enabledSpendPerSuppliers,
            enabledProducts: result.data.enabledProducts,
            enabledPurchaseOrder: result.data.enabledPurchaseOrder,
            enabledPurchaseOrderLinesFeature: result.data.enabledPurchaseOrderLinesFeature,
            enabledReportFilesFeature: result.data.enabledReportFilesFeature,
            enabledPurchaseOrderLineSpendsFeature: result.data.enabledPurchaseOrderLineSpendsFeature,
            enabledPurchaseOrderLineMilestonesFeature: result.data.enabledPurchaseOrderLineMilestonesFeature,
            enabledExceptionsFeature: result.data.enabledExceptionsFeature,
            enabledPublicTrackAndTraceFeature: result.data.enabledPublicTrackAndTraceFeature,
            enabledOverviewFeature: result.data.enabledOverviewFeature,
            enabledPortPairDataFeature: result.data.enabledPortPairDataFeature,
            enabledShipmentsFeature: result.data.enabledShipmentsFeature,
            enabledShipmentSpendsFeature: result.data.enabledShipmentSpendsFeature,
            enabledConsolidatedShipmentsSpendsFeature: result.data.enabledConsolidatedShipmentsSpendsFeature,
            enabledSiteDeliveredContainersFeature: result.data.enabledSiteDeliveredContainersFeature
          };
          setModel(model);
          stopProgress();
        })
        .catch(catchApiError);
    }
  }, []);

  const handleEnabledFeatureChange = (event) => {
    setModel({ ...model, [event.target.name]: event.target.checked });
  };

  const handleSubmit = (event) => {
    startProgress();
    event.preventDefault();
    customerAccountApiService
      .updateCustomerAccountFeatures(model)
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          var errorString = formErrorFactory.createErrorText(result.errors);
          showErrorSnackBar(errorString);
        } else {
          setDisableSaveButton(true);
          showSuccessSnackBar('Features updated successfully');
        }
        stopProgress();
      })
      .catch(catchApiError);
  };

  const canManage = () => {
    return userContext.isAdmin && userContext.permissions.canManageCustomerAccounts;
  };

  return (
    <>
      <h4 className={classes.title}> {model.accountName && model.accountName + ': '}Features Setting</h4>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <FormGroup row>
          <Grid container spacing={3}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledOverviewFeature}
                  featureName="enabledOverviewFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Overview"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledTrackAndTrace}
                  featureName="enabledTrackAndTrace"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Track and Trace"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledPublicTrackAndTraceFeature}
                  featureName="enabledPublicTrackAndTraceFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Public Track And Trace"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledTrendsOverTime}
                  featureName="enabledTrendsOverTime"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Trends Over Time"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledShipmentsFeature}
                  featureName="enabledShipmentsFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Shipments"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledPortPairDataFeature}
                  featureName="enabledPortPairDataFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Port Pair Data"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledCommercialInvoices}
                  featureName="enabledCommercialInvoices"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Commercial Invoices"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledConsolidatedShipmentsSpendsFeature}
                  featureName="enabledConsolidatedShipmentsSpendsFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Consolidated Shipments Spends"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledShipmentSpendsFeature}
                  featureName="enabledShipmentSpendsFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Shipment Spends"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledSpendPerSuppliers}
                  featureName="enabledSpendPerSuppliers"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Spend Per Suppliers"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledProducts}
                  featureName="enabledProducts"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Products"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledPurchaseOrder}
                  featureName="enabledPurchaseOrder"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Purchase Orders"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledPurchaseOrderLinesFeature}
                  featureName="enabledPurchaseOrderLinesFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Purchase Order Lines"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledPurchaseOrderLineSpendsFeature}
                  featureName="enabledPurchaseOrderLineSpendsFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Purchase Order Line Spends"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledPurchaseOrderLineMilestonesFeature}
                  featureName="enabledPurchaseOrderLineMilestonesFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Purchase Order Line Milestones"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledExceptionsFeature}
                  featureName="enabledExceptionsFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Exceptions Report"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledSiteDeliveredContainersFeature}
                  featureName="enabledSiteDeliveredContainersFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Site Delivered Containers Report"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledEDIReport}
                  featureName="enabledEDIReport"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Document Integrations"
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureSettingControl
                  featureEnabled={model.enabledReportFilesFeature}
                  featureName="enabledReportFilesFeature"
                  onChange={handleEnabledFeatureChange}
                  label="Enable Download Report Files"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {canManage() ? (
              <Grid item xs={12} className={classes.buttonsContainer}>
                <Button type="submit" buttonType="primary" disabled={disableSaveButton} className={classes.buttons}>
                  Save
                </Button>
                <Button buttonType="cancel" className={clsx(classes.buttons, classes.buttonRight)} onClick={() => props.history.goBack()}>
                  Cancel
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.buttonsContainer}>
                <Button buttonType="cancel" className={clsx(classes.buttons, classes.buttonRight)} onClick={() => props.history.goBack()}>
                  Back
                </Button>
              </Grid>
            )}
          </Grid>
        </FormGroup>
      </form>
    </>
  );
};

export default withRouter(FeaturesSettingEntry);
