import clsx from 'clsx';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useMediaLayout } from 'use-media';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Refresh from '@material-ui/icons/Refresh';

import NotificationPopup from 'src/components/parts/NotificationPopup';
import { DrawerContext } from 'src/contexts/DrawerContext';
import { ReloadContext } from 'src/contexts/ReloadContext';
import { UserContext } from 'src/contexts/UserContext';
import useAccounts from 'src/hooks/useAccounts';
import useComponentVisible from 'src/hooks/useComponentVisible';
import usePermissions from 'src/hooks/usePermissions';
import apiService from 'src/services/api.service';
import authService from 'src/services/auth.service';

import AccountsSelection from '../parts/AccountsSelection';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  topDrawerButtons: {
    color: theme.palette.menuDrawer.topButton,
    backgroundColor: 'rgb(255, 255, 255,0.6)',
    height: '32px',
    width: '32px',
    margin: '0 0 0 13px',
    '&:hover,&:focus': {
      backgroundColor: '#e0dbdb',
    },
  },
  minWidth: {
    minWidth: '35px',
  },
  avatar: {
    height: '35px',
    width: '35px',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: theme.palette.menuDrawer.main,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'rgb(190,190,190,0.3)',
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.menuDrawer.button,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    paddingLeft: '10px',
    flexGrow: 1,
    color: theme.palette.menuDrawer.text,
  },
  customBadge: {
    backgroundColor: '#BB86FC',
    color: 'white',
  },
  notificationPopupBox: {
    position: 'absolute',
    top: 60,
    right: 95,
    zIndex: 1500,
  },
}));

const TopMenuBar = (props) => {
  const classes = useStyles();
  const [profileUrl, setProfileUrl] = useState('');
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const openProfile = Boolean(profileAnchorEl);
  const [userContext, setUserContext] = useContext(UserContext);
  const [drawerContext, setDrawerContext] = useContext(DrawerContext);

  const [, setReloadContext] = useContext(ReloadContext);
  const [notificationBadge, setNotificationBadge] = useState();

  const isWide = useMediaLayout({ maxWidth: '1000px' });

  const history = useHistory({ forceRefresh: true });
  const { ref1, ref2, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { accountType, clearCurrentAccount } = useAccounts();
  const { hasFullPermissions, canReadCustomerAccounts, canReadVendorAccounts } = usePermissions();

  useEffect(() => {
    if (notificationBadge && !isComponentVisible) {
      var messages = [];
      var messagesToCleanUp = userContext.messages.filter((x) => x.isRead === false);

      for (let mess of messagesToCleanUp) messages.push(mess.id);

      if (messages.length > 0) {
        apiService.setMessageAsRead(messages).then(() => {
          //Reload messages
          apiService.getMe().then((result) => {
            setUserContext(result);
          });
        });
      }
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (isWide) {
      setDrawerContext(false);
    }
  }, [isWide]);

  useEffect(() => {
    setProfileUrl(
      userContext && userContext.avatarExtension
        ? '/dynamic/user/images/' + userContext.id + '.' + userContext.avatarExtension + '?v' + moment().format('YYYYMMDDhhmm')
        : ''
    );
    setNotificationBadge(userContext && userContext.messages && userContext.messages.filter((x) => x.isRead === false).length);
  }, [userContext]);

  const handleMenu = useCallback((event) => {
    setProfileAnchorEl(event.currentTarget);
  }, []);

  const handleLogout = useCallback(
    (event) => {
      authService.logout();
      setUserContext(null);
      clearCurrentAccount();
      history.push('/login');
    },
    [history]
  );

  const handleClose = useCallback(() => {
    setProfileAnchorEl(null);
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setDrawerContext(true);
  }, []);

  return (
    <>
      <div ref={ref1} className={classes.notificationPopupBox}>
        {isComponentVisible && (
          <div onClick={() => setIsComponentVisible(true)}>
            <NotificationPopup messages={userContext.messages} />
          </div>
        )}
      </div>
      <AppBar position="absolute" className={clsx(classes.appBar, drawerContext && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} style={{ background: 'transparent', boxShadow: 'none' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, drawerContext && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}></Typography>
          <AccountsSelection />
          <Tooltip title="Refresh">
            <IconButton color="inherit" className={classes.topDrawerButtons} onClick={() => setReloadContext(new Date())}>
              <Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications" ref={ref2}>
            <IconButton
              color="inherit"
              className={classes.topDrawerButtons}
              onClick={() => {
                isComponentVisible ? setIsComponentVisible(false) : setIsComponentVisible(true);
              }}
            >
              <Badge badgeContent={notificationBadge} classes={{ badge: classes.customBadge }}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <div>
            <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
              {userContext && userContext.id ? <Avatar src={profileUrl} className={classes.avatar}></Avatar> : ''}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={profileAnchorEl}
              getContentAnchorEl={null}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={openProfile}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  history.push('/profile');
                }}
              >
                <ListItemIcon className={classes.minWidth}>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </MenuItem>
              {(canReadVendorAccounts || canReadCustomerAccounts) && (
                <MenuItem
                  onClick={() => {
                    history.push('/account-profile');
                  }}
                >
                  <ListItemIcon className={classes.minWidth}>
                    <BusinessCenterIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Account Profile" />
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon className={classes.minWidth}>
                  <PowerSettingsNewIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </MenuItem>
            </Menu>
          </div>
          <div>
            <img src="src/../images/iconSmallFickleTitle.png" width="48" />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default TopMenuBar;
