import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import AccountType from 'src/models/AccountType';
import customerAccountApiService from 'src/services/customerAccount.api.service';

import useAccounts from './useAccounts';

const customerAccountFeaturesInitialState = {
  enabledTrackAndTrace: false,
  enabledEDIReport: false,
  enabledTrendsOverTime: false,
  enabledCommercialInvoices: false,
  enabledSpendPerSuppliers: false,
  enabledProducts: false,
  enabledPurchaseOrder: false,
  enabledShipmentSpendsFeature: false,
  enabledConsolidatedShipmentsSpendsFeature: false,
  enabledReportFilesFeature: false,
  enabledPurchaseOrderLineSpendsFeature: false,
  enabledPurchaseOrderLineMilestonesFeature: false,
  enabledPurchaseOrderLinesFeature: false,
  enabledOverviewFeature: false,
  enabledPortPairDataFeature: false,
  enabledShipmentsFeature: false,
  enabledExceptionsFeature: false,
  enabledSiteDeliveredContainersFeature: false,
};

const CustomerAccountFeaturesContext = createContext({ ...customerAccountFeaturesInitialState, featuresLoaded: false });

export const CustomerAccountFeaturesProvider = ({ children }) => {
  const { accountId, accountType } = useAccounts();
  const [customerAccountFeatures, setCustomerAccountFeatures] = useState(customerAccountFeaturesInitialState);
  const [loaded, setLoaded] = useState(false);

  const currentAccountId = useRef();

  useEffect(() => {
    const loadData = async () => {
      try {
        let apiResult = await customerAccountApiService.getCustomerAccountFeatures(accountId);
        setCustomerAccountFeatures(apiResult.data);
        setLoaded(true);
        console.log('Reload customerAccountFeatures', apiResult.data);
      } catch (err) {
        currentAccountId.current = null;
      }
    };

    if (accountId && accountId != currentAccountId.current && accountType) {
      currentAccountId.current = accountId;
      if (accountType == AccountType.Customer) {
        loadData();
      } else {
        setCustomerAccountFeatures(customerAccountFeaturesInitialState);
      }
    }
  }, [accountId, accountType]);

  return (
    <CustomerAccountFeaturesContext.Provider value={{ ...customerAccountFeatures, featuresLoaded: loaded }}>{children}</CustomerAccountFeaturesContext.Provider>
  );
};

const useCustomerAccountFeatures = () => {
  const context = useContext(CustomerAccountFeaturesContext);

  if (context === undefined) {
    throw new Error('useCustomerAccountFeatures must be used within CustomerAccountFeaturesContext');
  }

  return context;
};

export default useCustomerAccountFeatures;
