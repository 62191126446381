import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import DateFilterControl from 'src/components/parts/DataTableFilter/DateFilterControl';
import DelayChangedTextField2 from 'src/wrappers/DelayChangedTextField2';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  container: {
    paddingTop: 6,
  },
}));

const SiteDeliveredContainersTableFilter = ({ onChange }) => {
  const classes = useStyles();
  const [filterInputs, setFilterInputs] = useState({
    freeTextSearch: '',
  });
  const [filter, setFilter] = useState();

  const handleOnFilterValueChanged = useCallback((key, value) => {
    setFilter((f) => {
      const previousFilter = f ?? {};

      if (previousFilter[key] !== value) {
        let result = { ...previousFilter };
        result[key] = value;
        return result;
      } else {
        return previousFilter;
      }
    });
    console.log('handleOnFilterValueChanged', key, value);
  }, []);

  useEffect(() => {
    if (filter) {
      onChange && onChange(filter);
      console.log('Site Delivered Containers Filter', filter);
    }
  }, [filter, onChange]);

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={2} className={classes.container}>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
            <DelayChangedTextField2
              id="shipment-number-filter"
              label="Free Text Search"
              variant="outlined"
              value={filterInputs.freeTextSearch}
              onValueChange={(text) => {
                setFilterInputs((f) => ({
                  ...f,
                  freeTextSearch: text,
                }));
              }}
              onDelayValueChange={(text) => {
                handleOnFilterValueChanged('freeTextSearch', text);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <DateFilterControl
            id="eta-filter"
            title="ETA"
            onChange={(dateFilter) => {
              handleOnFilterValueChanged('eta', dateFilter);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SiteDeliveredContainersTableFilter;
