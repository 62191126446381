import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';

import { Backdrop, CircularProgress, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import ColumnSelector from 'src/components/parts/ColumnSelector';
import DataContentTable from 'src/components/parts/DataContentTable';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import useAccounts from 'src/hooks/useAccounts';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import pdfTableService from 'src/services/pdf.table.service';
import tableService from 'src/services/table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

import SiteDeliveredContainersTableFilter from './SiteDeliveredContainersTableFilter';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
}));

const defaultHeadCells = [
  {
    id: 'Address',
    numeric: false,
    disablePadding: true,
    label: 'Address',
  },
  {
    id: 'City',
    numeric: false,
    disablePadding: false,
    label: 'City',
  },
  {
    id: 'State',
    numeric: false,
    disablePadding: false,
    label: 'State',
  },
  {
    id: 'CountryName',
    numeric: false,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'Postcode',
    numeric: false,
    disablePadding: false,
    label: 'Postcode',
  },
  {
    id: 'ContainerType',
    numeric: false,
    disablePadding: false,
    label: 'Container Type',
  },
  {
    id: 'Total',
    numeric: true,
    disablePadding: false,
    label: 'Total',
  },
];

function SiteDeliveredContainersTable(props) {
  const classes = useStyles();
  const { accountId } = useAccounts();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Address');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [filter, setFilter] = useState({});
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [downloadCsv, setDownloadCsv] = useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();
  const { enabledSiteDeliveredContainersFeature } = useCustomerAccountFeatures();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const [destinations, setDestinations] = useState([]);

  const previousReload = useRef();
  const [reload, setReload] = useState(new Date());

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const createReportFilter = useCallback(() => {
    if (accountId) {
      const reportFilter = {
        ...filter,
        accountId: accountId,
        skip: page * rowsPerPage,
        take: rowsPerPage,
        sortColumn: orderBy,
        sortDirection: order,
      };

      return reportFilter;
    } else {
      return undefined;
    }
  }, [filter, order, orderBy, page, rowsPerPage, accountId]);

  const createTableListData = useCallback((items) => {
    let newArray = [];

    if (items) {
      items.forEach((item, index) => {
        const rowData = {
          id: index,
          Address: item.address,
          City: item.city,
          State: item.state,
          CountryName: item.countryName,
          Postcode: item.postcode,
          ContainerType: item.containerType,
          Total: item.total,
        };

        newArray.push(rowData);
      });
    }

    return newArray;
  }, []);

  const handleOnFilterChange = useCallback((data) => {
    console.log('handleOnFilterChange');
    setPage(0);
    setFilter((f) => ({ ...f, ...data }));
    setReload(new Date());
  }, []);

  const handleRequestSort = useCallback((sortOrder, property) => {
    console.log('handleRequestSort');
    setOrder(sortOrder);
    setOrderBy(property);
    setReload(new Date());
  }, []);

  const handleChangePage = useCallback((newPage) => {
    console.log('handleChangePage');
    setPage(newPage);
    setReload(new Date());
  }, []);

  const handleChangeRowsPerPage = useCallback((newValue) => {
    console.log('handleChangeRowsPerPage');
    setPage(0);
    setRowsPerPage(newValue);
    setReload(new Date());
  }, []);

  const generatePdf = useCallback(() => {
    if (totalNumberOfRows > 0) {
      const reportFilter = createReportFilter();
      const filterForPdf = {
        ...reportFilter,
        skip: 0,
        take: totalNumberOfRows,
      };
      apiService
        .getSiteDeliveredContainersTableReport(filterForPdf)
        .then((result) => {
          stopProgress();
          pdfTableService.generateSiteDeliveredContainersPDF(
            result.data.items,
            'Site Delivered Containers',
            'Site-Delivered-Containers-Report',
            result.data.hiddenColumnNames
          );
        })
        .catch(catchApiError);
    }
  }, [catchApiError, createReportFilter, stopProgress, totalNumberOfRows]);

  const generateCSV = useCallback(() => {
    if (totalNumberOfRows > 0) {
      const reportFilter = createReportFilter();
      const filterForCsv = {
        ...reportFilter,
        skip: 0,
        take: totalNumberOfRows,
      };
      apiService
        .getSiteDeliveredContainersTableReport(filterForCsv)
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generateSiteDeliveredContainersCsvData(result.data.items, '', result.data.hiddenColumnNames);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  }, [catchApiError, createReportFilter, stopProgress, totalNumberOfRows]);

  const handleColumnSelected = useCallback((headCells) => {
    setNewHeadCells(headCells);
  }, []);

  const handleBackdropClose = useCallback(() => {
    setOpenBackdrop(false);
  }, []);

  useEffect(() => {
    const getData = (reportFilter) => {
      apiService
        .getSiteDeliveredContainersTableReport(reportFilter)
        .then((result) => {
          let contentRows = createTableListData(result.data.items);
          let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
          setHeadCells(filteredTable.headCells);
          setRows(filteredTable.contentRows);
          setTotalNumberOfRows(result.data.count);
          setHiddenColumnNames(result.data.hiddenColumnNames);
          setDestinations(result.data.destinations);
          stopProgress();
          handleBackdropClose();
        })
        .catch(catchApiError);
    };

    const reportFilter = createReportFilter();
    if (reportFilter && previousReload.current !== reload) {
      console.log('getData')
      previousReload.current = reload;
      startProgress();
      getData(reportFilter);
    }
  }, [reload, createReportFilter, startProgress, catchApiError, createTableListData, stopProgress, handleBackdropClose]);

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <EntityListFrame>
        {enabledSiteDeliveredContainersFeature ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4>Site Delivered Containers</h4>
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SiteDeliveredContainersTableFilter onChange={handleOnFilterChange} destinations={destinations} />
            </Grid>
            <Divider />

            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={newHeadCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}

export default withRouter(SiteDeliveredContainersTable);
